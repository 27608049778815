import React from "react";
import PropTypes from 'prop-types';
import PlayerRankUpModal from "./PlayerRankUpModal";

class XPProgressMeter extends React.Component {
    constructor() {
        super();

        this.state = {
            showModal: false,
        }
    }

    componentDidUpdate(prevProps, prevState) {
        if (this.props.oldXP !== this.props.newXP){
            // set both bars to oldXP

            if (this.props.showing){
                // animate new-progress bar to newXP

                // if bar hits end (displayed xp goes at or over current threshold), pop the modal by setting state.newRank
            }
        }
    }


    render() {
        console.log('XPProgressMeter: props?', this.props);

        return (
            <div className={'xp-progress-meter'}>
                <PlayerRankUpModal id={'level-up-confirm'} oldRank={this.state.oldRank} newRank={this.state.newRank}></PlayerRankUpModal>
            </div>
        );
    }
}

XPProgressMeter.propTypes = {
    showing: PropTypes.bool,
    oldXP: PropTypes.number,
    newXP: PropTypes.number
};

export default XPProgressMeter;
