export const GAME_SCENE_MAIN_MENU = 0;
export const GAME_SCENE_GAMEPLAY = 1;
export const GAME_SCENE_GAMEPLAY_END_DIALOG = 2;

export const PLAYER_XP_LEVELS = [1,3];

export const POINTS_PER_COIN = 1;

export const CONSTRAINED_ASPECT = 1 / 2.15;
export const WINDOW_HEIGHT_PX = window.innerHeight;
export const WINDOW_FULL_WIDTH_PX = window.innerWidth;
export const WINDOW_CONSTRAINED_WIDTH_PX = WINDOW_HEIGHT_PX * CONSTRAINED_ASPECT;
export const PPM = WINDOW_HEIGHT_PX / 10;

export const BONUS_TYPE_SKIP = "-";
export const BONUS_TYPE_DOUBLE = "*2";
// export const BONUS_TYPE_PLUS_10 = "+10";
export const BONUS_TYPE_PLUS_20 = "+20";
export const BONUS_TYPE_PLUS_30 = "+30";
export const BONUS_TYPE_PLUS_40 = "+40";
export const BONUS_TYPE_COIN = "$$";
export const BONUS_TYPE_EXTRA_TURN = "!1";
