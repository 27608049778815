// note: some of these aren't necessarily bad, e.g. areola, but it would be awkward for some of these to appear
// spelled out already when the word appears.  You may find some of these as valid answers, and that's ok (sometimes)
// Other words are just totally awful, and will never appear as valid answers.
// Other words are only bad in some contexts, and it was a total judgment call as to whether to block them, e.g. beaver
// Other words are just age inappropriate, e.g. whiskey
export const DISPLAY_BLOCKLIST = [
    'abuse',
    'addict',
    'allah',
    'anus',
    'areola',
    'aroused',
    'arse',
    'asses',
    'asshole',
    'asshore',
    'asskiss',
    'asslick',
    'asslove',
    'assman',
    'asswipe',
    'banging',
    'bastard',
    'bazooms',
    'beaner',
    'beatoff',
    'beaver',
    'bestial',
    'biatch',
    'bigass',
    'bigbutt',
    'bitch',
    'biteme',
    'blowjob',
    'bollick',
    'bollock',
    'bondage',
    'boner',
    'bong',
    'boob',
    'boong',
    'breast',
    'brothel',
    'bugger',
    'bunga',
    'buttman',
    'byatch',
    'cacker',
    'chinese',
    'chink',
    'choad',
    'chode',
    'clit',
    'clogwog',
    'cocknob',
    'coitus',
    'colored',
    'commie',
    'condom',
    'coolie',
    'cooly',
    'coon',
    'crotch',
    'cumfest',
    'cummer',
    'cumming',
    'cumquat',
    'cumshot',
    'cunntt',
    'cunt',
    'dago',
    'dahmer',
    'dammit',
    'damnit',
    'darkie',
    'darky',
    'demon',
    'dick',
    'diddle',
    'dike',
    'dildo',
    'dumbass',
    'dyke',
    'eatme',
    'ecstacy',
    'erect',
    'escort',
    'faeces',
    'fagging',
    'faggot',
    'fagot',
    'fatass',
    'fckcum',
    'felatio',
    'felch',
    'felcher',
    'feltch',
    'fetish',
    'fister',
    'fisting',
    'flasher',
    'fondle',
    'fubar',
    'fucck',
    'fuck',
    'fugly',
    'fuuck',
    'genital',
    'getiton',
    'ginzo',
    'giz',
    'goddamn',
    'goyim',
    'gringo',
    'gubba',
    'gummer',
    'gyppie',
    'gyppo',
    'gyppy',
    'hamas',
    'handjob',
    'harder',
    'hardon',
    'harem',
    'hell',
    'heroin',
    'herpes',
    'hindoo',
    'hiscock',
    'hitler',
    'hodgie',
    'homo',
    'honger',
    'honkey',
    'honky',
    'hooker',
    'hooters',
    'horney',
    'horny',
    'hosejob',
    'hotdamn',
    'hussy',
    'hustler',
    'hymen',
    'hymie',
    'iblowu',
    'illegal',
    'incest',
    'insest',
    'jackass',
    'jackoff',
    'japcrap',
    'jerkoff',
    'jewish',
    'jigaboo',
    'jigga',
    'jigger',
    'jihad',
    'jimfish',
    'jiz',
    'juggalo',
    'kanake',
    'kigger',
    'kike',
    'kinky',
    'kissass',
    'kkk',
    'knockers',
    'kondum',
    'koon',
    'kummer',
    'kumming',
    'kunt',
    'kyke',
    'lesbain',
    'lesbayn',
    'lesbian',
    'lesbin',
    'lezbo',
    'lezzo',
    'libido',
    'licker',
    'lickme',
    'lolita',
    'lovegoo',
    'lovegun',
    'lubejob',
    'lynch',
    'macaca',
    'milf',
    'mockey',
    'mockie',
    'mofo',
    'molest',
    'mormon',
    'moslem',
    'mulatto',
    'muncher',
    'muslim',
    'naked',
    'nastyho',
    'nazi',
    'negro',
    'negroes',
    'negroid',
    'negros',
    'niger',
    'nigga',
    'nigger',
    'niggle',
    'niggor',
    'niggur',
    'niglet',
    'nignog',
    'nipple',
    'nookey',
    'nookie',
    'nude',
    'nudger',
    'orgasim',
    'orgasm',
    'orgies',
    'orgy',
    'osama',
    'pansies',
    'panties',
    'pecker',
    'peehole',
    'peepee',
    'penile',
    'penis',
    'period',
    'perv',
    'phuked',
    'phuking',
    'phukked',
    'phungky',
    'piker',
    'pikey',
    'pimp',
    'pissed',
    'pisser',
    'pisses',
    'pissing',
    'pissoff',
    'playboy',
    'poon',
    'poop',
    'porn',
    'prick',
    'pube',
    'pubic',
    'pudboy',
    'puddboy',
    'puntang',
    'pussie',
    'pussy',
    'quashie',
    'queef',
    'queer',
    'quickie',
    'racial',
    'racist',
    'raghead',
    'rape',
    'rapist',
    'rectum',
    'reestie',
    'refugee',
    'retard',
    'rimjob',
    'rimming',
    'satan',
    'schlong',
    'scrotum',
    'semen',
    'sex',
    'shaggin',
    'shat',
    'sheeney',
    'shit',
    'skank',
    'slapper',
    'slave',
    'slopey',
    'slopy',
    'slut',
    'smut',
    'sodomy',
    'spank',
    'sperm',
    'spic',
    'spik',
    'spooge',
    'squaw',
    'stiffy',
    'strapon',
    'suck',
    'suicide',
    'swallow',
    'tampon',
    'tantra',
    'tarbaby',
    'teste',
    'tinkle',
    'titjob',
    'tits',
    'tittie',
    'titty',
    'torture',
    'tosser',
    'trannie',
    'tranny',
    'trojan',
    'turnon',
    'twat',
    'twink',
    'upskirt',
    'urinary',
    'urinate',
    'urine',
    'uterus',
    'vagina',
    'virgin',
    'vulva',
    'wank',
    'waysted',
    'weenie',
    'weewee',
    'welcher',
    'wetback',
    'wetspot',
    'whacker',
    'whigger',
    'whiskey',
    'whites',
    'whitey',
    'whiz',
    'whore',
    'wigger',
    'willie',
    'willy',
    'wtf',
    'wuss',
    'wuzzie',
    'yankee',
    'zigabo'
];