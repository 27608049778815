import {
    BONUS_TYPE_COIN,
    BONUS_TYPE_SKIP,
    BONUS_TYPE_PLUS_20,
    BONUS_TYPE_PLUS_30,
    BONUS_TYPE_PLUS_40,
    BONUS_TYPE_DOUBLE,
    BONUS_TYPE_EXTRA_TURN} from "../const/const";

export class BonusTypeProvider
{
    AddABunch(bonusType, howMany) {
        for (let i = 0; i < howMany; i++){
            this.bonusTypes.push(bonusType);
        }
    }

    constructor(level)
    {
        this.bonusTypes = [];

        this.AddABunch(BONUS_TYPE_SKIP, 10);
        this.AddABunch(BONUS_TYPE_PLUS_20, 10);
        this.AddABunch(BONUS_TYPE_COIN, 30);

        if (level < 20)
        {
            this.AddABunch(BONUS_TYPE_SKIP, 10);
        }

        if (level < 10)
        {
            this.AddABunch(BONUS_TYPE_SKIP, 10);
        }

        if (level > 20)
        {
            this.AddABunch(BONUS_TYPE_PLUS_30, 10);
            this.AddABunch(BONUS_TYPE_COIN, 5);
        }

        if (level > 40)
        {
            this.AddABunch(BONUS_TYPE_DOUBLE, 10);
            this.AddABunch(BONUS_TYPE_EXTRA_TURN, 10);
            this.AddABunch(BONUS_TYPE_COIN, 5);
        }

        if (level > 60)
        {
            this.AddABunch(BONUS_TYPE_PLUS_40, 10);
            this.AddABunch(BONUS_TYPE_COIN, 5);
        }

        if (level > 100)
        {
            this.AddABunch(BONUS_TYPE_PLUS_40, 10);
        }
    }

    GetBonus()
    {
        const r = Math.floor(Math.random() * this.bonusTypes.length);
        return this.bonusTypes[r];
    }
}