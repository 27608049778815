import React from "react";

class SettingsButton extends React.Component {
    render() {
        return (
            <div className={'settings-button'}>
                Settings Button here.
            </div>
        );
    }
}
export default SettingsButton;
