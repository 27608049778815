import React from 'react';
import PropTypes from 'prop-types';

class Showable extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            readyToAnimateContents: false
        }
    }

    componentDidUpdate(prevProps) {
        if (this.props.showing !== undefined && this.props.showing !== prevProps.showing) {
            if (this.props.showing){
                this.doShowTransition();
            }
            else
            {
                this.doHideTransition();
            }
        }
    }

    doShowTransition(){
        // do some gsap stuff, then...
        this.setState({readyToAnimateContents: true});
    }

    doHideTransition(){
        this.setState({readyToAnimateContents: false});
    }
}

Showable.propTypes = {
    showing: PropTypes.bool,
}

export default Showable;
