import React from "react";
import ModalDialog from "./ModalDialog";

class HomeButton extends React.Component {
    render() {
        return (
            <>
                <div className={'home-button'}>
                    Home Button here.
                </div>
                <ModalDialog id={'home-confirm'} showing={false}></ModalDialog>
            </>
        );
    }
}
export default HomeButton;
