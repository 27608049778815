import React from "react";
import "../../app.styles.scss";
import ModalDialog from "./ModalDialog";
import HomeButton from "./HomeButton";
import SettingsButton from "./SettingsButton";
import EndDialog from "./EndDialog";
import { GAME_SCENE_MAIN_MENU, GAME_SCENE_GAMEPLAY, GAME_SCENE_GAMEPLAY_END_DIALOG } from '../const/const';
import GamePlay from "./GamePlay";
import MainMenu from "./MainMenu";


class App extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
        }
    }

    componentDidMount() {
        // setting react app on gameCore calls back and sets our state, so have to wait until component mounts
        this.props.gameCore.setReactApp(this);
    }

    TestEndDialog(){
        console.log('setting game scene', window.GameCore._GameScene);
        window.GameCore._GameScene = window.GameCore._GameScene + 1;
        console.log('set game scene', window.GameCore._GameScene);
    }

    SetStateVal(statePropName, value){
        this.setState({ [statePropName]: value });
    }


    render() {
        return (
            <div className={'app'}>
                <div className={'app-background'}></div>
                <MainMenu id={'mainMenu'} showing={this.state._GameScene === GAME_SCENE_MAIN_MENU}></MainMenu>
                <GamePlay id={'pixiApp'} showing={this.state._GameScene === GAME_SCENE_GAMEPLAY } gameCore={this.props.gameCore}></GamePlay>
                <HomeButton id={'home-button'}/>
                <SettingsButton id={'settings-button'}/>
            </div>
        );
    }
}

export default App;
