import * as PIXI from "pixi.js";
import * as particles from 'pixi-particles'
import AnimatedContainer from "./animatedContainer";

export default class ChooserLetter extends AnimatedContainer {
    constructor() {
        super();

        this.emitterContainer = new PIXI.Container();
        this.addChild(this.emitterContainer);

        // const particle = PIXI.Loader.shared.resources['particle'];
        const sheet = PIXI.Loader.shared.resources['lettersSpriteSheet'].spritesheet;
        const letterTexture = sheet.textures['letterQ'];


        this.emitter = new particles.Emitter(
            this.emitterContainer,
            [letterTexture],
            emitterConfig
        );
        this.emitter.emit = false;

        this.letterContainer = new PIXI.Container();
        this.addChild(this.letterContainer);
        this.letterSprite = null;

        this.unselect();
    }

    setLetter(letter){
        this.letterContainer.removeChildren();
        const textureName = `letter${letter.toUpperCase()}`;
        const sheet = PIXI.Loader.shared.resources['lettersSpriteSheet'].spritesheet;
        const letterTexture = sheet.textures[textureName];
        this.letterSprite = new PIXI.Sprite(letterTexture);
        this.letterContainer.addChild(this.letterSprite);
    }

    // needed because we have to manually move the emitter
    setPosition(x, y){
        this.x = x;
        this.y = y;
        //this.emitter.updateOwnerPos(x, y);
        //this.emitter.updateSpawnPos(x, y);
    }

    unselect() {
        if (this.letterSprite){
            this.letterSprite.scale.x = this.letterSprite.scale.y = 1;
        }

        this.emitter.emit = false;
    }

    select() {
        if (this.letterSprite){
            this.letterSprite.scale.x = this.letterSprite.scale.y = 1.2;
        }

        this.emitter.emit = true;
    }

    onEnterFrame(deltaSeconds) {
        this.emitter.update(deltaSeconds);
    }
}

const emitterConfig = {
    "alpha": {
        "start": 1,
        "end": 0
    },
    "scale": {
        "start": 0.1,
        "end": 0.01,
        "minimumScaleMultiplier": 1
    },
    "color": {
        "start": "#ffe47a",
        "end": "#ffc400"
    },
    "speed": {
        "start": 20,
        "end": 30,
        "minimumSpeedMultiplier": 1
    },
    "acceleration": {
        "x": 0,
        "y": 0
    },
    "maxSpeed": 0,
    "startRotation": {
        "min": 0,
        "max": 360
    },
    "noRotation": false,
    "rotationSpeed": {
        "min": 0,
        "max": 0
    },
    "lifetime": {
        "min": 4,
        "max": 6
    },
    "blendMode": "normal",
    "frequency": 0.02,
    "emitterLifetime": -1,
    "maxParticles": 90,
    "pos": {
        "x": 0,
        "y": 0
    },
    "addAtBack": false,
        "spawnType": "circle",
        "spawnCircle": {
        "x": 0,
            "y": 0,
            "r": 0
    }
}