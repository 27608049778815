import React from "react";
import gsap from "gsap";

class ModalDialog extends React.Component {
    constructor(props) {
        super(props);
    }
    static defaultProps = {
        showing: false
    }

    render() {
        return (
            <>
                { this.props.showing && (
                    <div className={'modal-dialog'}>
                        <h1>MODAL</h1>
                    </div>
                )}
            </>
        );
    }

    componentDidUpdate(prevProps){ // , prevState}, snapshot){
        if (prevProps.showing !== this.props.showing) {
            // do an animation
            gsap.fromTo(this, {scale: .001}, {scale: 1, duration: .6, ease: 'elastic'});
        }
    }
}
export default ModalDialog;
