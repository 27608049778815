import {POINTS_PER_COIN} from "./const/const";

class CoreBase {
    constructor(defaults) {
        this.defaults = defaults;
    }

    setReactApp(reactApp){
        this.reactApp = reactApp;

        // now that we have the app to set state on, reset session-only state vars
        for (const key in this.defaults){
            if (key.startsWith('_')){
                this.setProp(key, this.defaults[key]);
            }
        }

    }

    resetToDefaults(){
        for (var key in this.defaults){
            this.setProp(key, this.defaults[key]);
        }
    }

    getStringProp(propKey){
        const stickyValue = window.localStorage.getItem(propKey);
        if (stickyValue !== null)
            return JSON.parse(stickyValue);

        return (propKey in this.defaults) ? this.defaults[propKey] : "";
    }

    getIntProp(propKey){
        const stickyValue = window.localStorage.getItem(propKey);
        if (stickyValue !== null)
            return parseInt(JSON.parse(stickyValue));

        return (propKey in this.defaults) ? this.defaults[propKey] : 0;
    }

    getFloatProp(propKey){
        const stickyValue = window.localStorage.getItem(propKey);
        if (stickyValue !== null)
            return parseFloat(JSON.parse(stickyValue));

        return (propKey in this.defaults) ? this.defaults[propKey] : 0;
    }

    getBoolProp(propKey){
        const stickyValue = window.localStorage.getItem(propKey);
        if (stickyValue !== null)
            return JSON.parse(stickyValue).toLowerCase() === 'true';

        return (propKey in this.defaults) ? this.defaults[propKey] : false;
    }

    setProp(propKey, value){
        console.log('setting state val, react app?', this.reactApp);
        window.localStorage.setItem(propKey, JSON.stringify(value));
        //this.reactApp.SetStateVal(propKey, value);
        this.reactApp.setState({ [propKey]: value });
    }
}

export default class GameCore extends CoreBase {
    constructor() {
        console.log('GameCore constructor');
        if (window.GameCore){
            console.error('Only one instance of GameCore allowed');
            throw 'Only one instance of GameCore allowed';
        }

        // leading _ indicates session only, and will be reset when game restarts
        const defaults = {
            _EndDialogShowing: false,
            _GameScene: 0,
            GameLevel: 1,
            PlayerLevel: 0,
            PlayerXP: 0,
            MainCoins: 0,
            LastRewardedGoalIndex: 0,
            UnrewardedGoalIndex: 0,
            NextGoalAltitude: 0,
            HighestAltitude: 0
        };

        super(defaults);
        window.GameCore = this;

        this.totalLevels = 1;
        this.turnsLeft = 0;

        // ALL THIS STUFF IS FOR SHOWING END OF LEVEL DIALOG
        this.coinsThisLevel = 0;
        this.goalCompletionXP = 0;
        this.scoreXP = 0;               // XP gained by the word score on this level
        this.coinsXP = 0;               // XP gained from coin bonus
        // rest can be local to levelComplete func
    }

    getLevelXPThresholds(){
        return [1];
    }

    get GameLevel() {
        return this.getIntProp('GameLevel');
    }

    set GameLevel(value){
        this.setProp('GameLevel', value);
    }

    get PlayerLevel() {
        return this.getIntProp('PlayerLevel');
    }

    set PlayerLevel(value){
        this.setProp('PlayerLevel', value);
    }

    get Multiplier () {
        // MUSTFIX fix this, calc from player level?
        return 1.5;
    }

    get MainCoins() {
        return this.getIntProp('MainCoins');
    }

    set MainCoins(value){
        throw 'Dont set MainCoins directly!  Use AddMainCoins';
    }

    AddMainCoins(howMany){
        this.coinsThisLevel += howMany;
        this.setProp('MainCoins', this.MainCoins + howMany);
    }

    get PlayerXP() {
        return this.getIntProp('PlayerXP');
    }

    set PlayerXP(value){
        this.setProp('PlayerXP', value);
    }

    // we won't reward players until end dialog, but if they exit app,
    // we don't want them to lose their reward.  Track what's been rewarded
    // separately from what hasn't, and resolve the two when end dialog shows
    get LastRewardedGoalIndex() {
        return this.getIntProp('LastRewardedGoalIndex', 0);
    }

    set LastRewardedGoalIndex(value){
        this.setProp('LastRewardedGoalIndex', value);
    }

    get UnrewardedGoalIndex() {
        return this.getIntProp('UnrewardedGoalIndex', 0);
    }

    set UnrewardedGoalIndex(value){
        this.setProp('UnrewardedGoalIndex', value);
    }

    get NextGoalAltitude() {
        return this.getIntProp('NextGoalAltitude', 0);
    }

    set NextGoalAltitude(value){
        this.setProp('NextGoalAltitude', value);
    }

    get HighestAltitude() {
        return this.getIntProp('HighestAltitude', 0);
    }

    set HighestAltitude(value){
        this.setProp('HighestAltitude', value);
    }

    /*********************************************************************
     * SESSION ONLY STUFF GOES HERE
     */

    get _GameScene() {
        return this.getIntProp('_GameScene', 0);
    }

    set _GameScene(value){
        this.setProp('_GameScene', value);
    }

    get _UnmultipliedLevelScore() {
        return this.getIntProp('_UnmultipliedLevelScore', 0);
    }

    set _UnmultipliedLevelScore(value){
        this.setProp('_UnmultipliedLevelScore', value);
    }

    get _LevelScore() {
        return this.getIntProp('_LevelScore', 0);
    }

    set _LevelScore(value){
        this.setProp('_LevelScore', value);
    }

    get _GoalBonus() {
        return this.getIntProp('_GoalBonus', 0);
    }

    set _GoalBonus(value){
        this.setProp('_GoalBonus', value);
    }

    get _CoinsThisLevel() {
        return this.getIntProp('_CoinsThisLevel', 0);
    }

    set _CoinsThisLevel(value){
        this.setProp('_CoinsThisLevel', value);
    }

    get _SettingsDialogShowing() {
        return this.getBoolProp('_SettingsDialogShowing');
    }

    set _SettingsDialogShowing(value){
        this.setProp('_SettingsDialogShowing', value);
    }

    getUnmultipliedScore(){
        return this._UnmultipliedLevelScore + this._GoalBonus + _CoinsThisLevel * POINTS_PER_COIN;
    }

    // may do something fancier here in the future
    getTotalScore(){
        return Math.round(this.getUnmultipliedScore() * this.Multiplier);
    }


    startLevel(){
        this.coinsThisLevel = 0;
        this.levelScore = 0;
        this.levelCompletionBonus = 0;
        this.unmultipledXp = this.unmultipliedScore = 0;
        this.xpEarned = 0;
    }

    completeLevel(){

    }

    getBonus(gameLevel = false){
        if (gameLevel === false)
            gameLevel = this.GameLevel;
        // returns bonus, based on current GameLevel
    }

    getAltitudeGainForScore(score){
        return score * this.Multiplier;
    }

    // call getAltitudeForScore, and pass it in, along with score
    completeTurn(score, altitudeGain, coins, extraTurns){

    }
}

export class GameSimulator{
    constructor(skillLevel = 0) {
        this.skillLevel = skillLevel;
    }
}