import {DISPLAY_BLOCKLIST} from "../const/blocklist";


export default class Jumble {
    constructor (rawJumbleCombo, shuffledJumble = false, originalOrder = false){
        this.validMatches = [];
        this.originalAnswer = '';
        this.letters = '';

        // only used in tutorials
        this.actualOrder = [];

        if (shuffledJumble !== false && originalOrder !== false){
            // we're doing a tutorial jumble, set things up differently
            this.letters = shuffledJumble;
            this.actualOrder = originalOrder;
            this.validMatches = [rawJumbleCombo];
            this.originalAnswer = rawJumbleCombo;
        }
        else {
            const pieces = rawJumbleCombo.split('|');
            this.validMatches = pieces.slice(2);
            this.originalAnswer = pieces[1];
            this.letters = this.originalAnswer;

            let i = 0;
            // attempt up to 1000 shuffles.  If not, showing original isn't the end of the world
            for (; i < 1000 && this.RejectShuffledWord(this.originalAnswer, this.letters); i++);

            // if we couldn't find one, DONT leave it how it is, it might be on a bad word
            if (i >= 999){
                this.letters = this.originalAnswer;
            }
        }
    }

    FisherYatesShuffle(letters){
        const arr = letters.split('');
        let i = arr.length, randIndex , temp;
        while(--i > 0){
            randIndex = Math.floor(Math.random() * (i+1));
            temp = arr[randIndex];
            arr[randIndex] = arr[i];
            arr[i] = temp;
        }

        return arr.join('');
    }

    RejectShuffledWord(originalAnswer, letters){
        // we'll need to be checking for circular occurences, e.g. 'carrot' occurring in 'rotcar', because it loops
        const twiceForward = originalAnswer + originalAnswer;
        const backward = letters.split("").reverse().join("");
        const twiceBackward = backward + backward;

        if (twiceForward.includes(originalAnswer) || twiceBackward.includes(originalAnswer)){
            return true;
        }

        for (var b of DISPLAY_BLOCKLIST){
            if (twiceForward.includes(b) || twiceBackward.includes(b)){
                return true;
            }
        }

        return false;
    }

    IsValid(guess){
        return this.validMatches.includes(guess);
    }
    // add params to constructor to allow setting up tutorial jumble
}