import * as PIXI from "pixi.js";

export default class AnimatedContainer extends PIXI.Container {
    constructor() {
        super();
    }

    onEnterFrame(deltaSeconds){

    }
}