import React from "react";
import PropTypes from 'prop-types';
import ModalDialog from "./ModalDialog";
import gsap from "gsap";

class PlayerRankUpModal extends ModalDialog {
    constructor(props) {
        super(props);
    }
    static defaultProps = {
        showing: false
    }

    componentDidUpdate(prevProps, prevState) {
        if (this.props.oldRank !== this.props.newRank){
            this.setState({showing: true});
            // and gsap animate it
        }
    }


    render() {
        if (!this.props.showing){
            return (<></>);
        }

        return(
            <div className={'modal-dialog'}>
                <h1>MODAL</h1>
            </div>
        );
    }
}

PlayerRankUpModal.propTypes = {
    showing: PropTypes.bool,
    oldRank: PropTypes.number,
    newRank: PropTypes.number,
    oldMultiplier: PropTypes.number,
    newMultiplier: PropTypes.number,
    coins: PropTypes.number,
};


export default PlayerRankUpModal;
