import React from "react";
import Showable from "./Showable";
import EndDialog from "./EndDialog";
import PropTypes from "prop-types";
import {POINTS_PER_COIN, WINDOW_FULL_WIDTH_PX, WINDOW_HEIGHT_PX} from "../const/const";
import * as PIXI from "pixi.js";
import LetterChooser from "../pixi/letterChooser";
import {BonusTypeProvider} from "../pixi/bonusTypeProvider";
import {Jumbles} from "../pixi/jumbles";


class GamePlay extends Showable {
    constructor(props) {
        super(props);

        this.gameCore = this.props.gameCore;
        this.points = 0;
        this.levelScore = 0;
        this.goalBonus = 0;
        this.coinsThisLevel = 0;
        this.coinMultiplier = 1;
        this.startingXP = this.gameCore.PlayerXP;
        this.goalsAchieved = 0;

        this.state = {
            levelComplete: false
        }

        this.pixiContainer = null;
        this.pixiApp = new PIXI.Application({width:window.innerWidth, height:window.innerHeight});
        this.stage = this.pixiApp.stage;
    }

    onAnimationFrame(timestamp){
        const deltaSeconds = (timestamp - this.lastTime) / 1000;
        this.lastTime = timestamp;
        this.letterChooser.onEnterFrame(deltaSeconds);
        window.requestAnimationFrame((ts) => {this.onAnimationFrame(ts);});
    }

    testAddPoints = () => {
        console.log('testAddPoints');
        this.points += 90;
    }
    testGetCoin = () => {
        console.log('testGetCoin');
        this.coinsThisLevel += 1;
    }
    testNextGoal = () => {
        console.log('testNextGoal');
        this.goalsAchieved++;
        this.gameCore.UnrewardedGoalIndex++;
        this.gameCore._GoalBonus += 1000;       // MUSTFIX: not 1000, do some math here.
    }
    winLevel = () => {
        console.log('level complete');
        this.setState({'levelComplete': true});
    }
    testLoadJumble = () => {
        const jumble = Jumbles.GetJumble(60);
        console.log('test load jumble', jumble);
        this.bonusTypeProvider = new BonusTypeProvider(60);
        this.letterChooser.loadJumble(jumble, this.bonusTypeProvider);
    }

    componentDidUpdate(prevProps, prevState){
        if (this.props.showing && this.props.showing !== prevProps.showing){

            this.letterChooser = new LetterChooser();
            // TODO: create this.movables, also, make sure this only fires once per run
            this.stage.addChild(this.letterChooser);
            console.log('Stage size:', this.stage.width, this.stage.height);
            this.letterChooser.x = WINDOW_FULL_WIDTH_PX / 2;
            this.letterChooser.y = WINDOW_HEIGHT_PX / 2;

            window.requestAnimationFrame((ts) => {this.onAnimationFrame(ts);});
            this.lastTime = false;

            let sheet = PIXI.Loader.shared.resources['lettersSpriteSheet'].spritesheet;
            // initialize background sprite
            const background = new PIXI.Sprite(sheet.textures["letterA"]);
            this.stage.addChild(background);
        }
    }

    //    HERE: was going to drop in darkened pic of gameplay as contents of gameplay, then add buttons for
    //    getting a coin, scoring, getting goal, winning level. Make sure end dialog gets all data.
    getPixiContainerRef = (pcr) => {
        // the element is the DOM object that we will use as container to add pixi stage(canvas)
        this.pixiContainer = pcr;
        if (this.pixiContainer && this.pixiContainer.children.length <= 0){
            this.pixiContainer.appendChild(this.pixiApp.view);
        }
    };

    render() {
        return (
            <div id={'pixiApp'} className={'pixi-app'} style={{left: this.props.showing ? '0' : '99999px'}}>
                <div className={'test-buttons'}>
                    <button onClick={this.testAddPoints}>Add 90 pts</button>
                    <button onClick={this.testGetCoin}>Get Coin</button>
                    <button onClick={this.testNextGoal}>Get Next Goal</button>
                    <button onClick={this.winLevel}>Win Level</button>
                    <button onClick={this.testLoadJumble}>Load jumble</button>
                </div>
                <div ref={this.getPixiContainerRef}></div>
                <EndDialog
                    showing={this.state.levelComplete}
                    levelScore={this.gameCore._LevelScore}
                    goalBonus={this.gameCore._GoalBonus}
                    coinsThisLevel={this.gameCore._CoinsThisLevel}
                    coinMultiplier={POINTS_PER_COIN}
                    oldXP={this.startingXP}
                    newXP={this.gameCore.PlayerXP}
                ></EndDialog>
            </div>
        );
    }
}

GamePlay.propTypes = {
    gameCore: PropTypes.object,
};

export default GamePlay;
