import React from "react";
import PropTypes from 'prop-types';
import Showable from "./Showable";
import XPProgressMeter from "./XPProgressMeter";
import {PLAYER_XP_LEVELS} from "../const/const";

class EndDialog extends Showable {
    constructor(props) {
        super(props);

        this.state = {
            readyToAnimateContents: false,
        };

        // for animating
        this.mainDiv = null;
    }

    componentDidUpdate(prevProps, prevState) {
        if (this.props.showing !== undefined && this.props.showing !== prevProps.showing) {
            if (this.props.showing){
                this.doShowTransition();
            }
            else
            {
                this.doHideTransition();
            }
        }

        if (this.state.readyToAnimateContents !== prevState.readyToAnimateContents && this.state.readyToAnimateContents) {

        }
    }

    doShowTransition(){
        // do some gsap stuff, then...
        this.setState({readyToAnimateContents: true});
    }

    doHideTransition(){
        this.setState({readyToAnimateContents: false});
    }

    render() {
        if (!this.props.showing){
            return null;
        }

        return (
            <div className={'end-dialog'} ref={div => this.mainDiv = div}>
                <XPProgressMeter id={'xp-progress-meter'}
                                 showing={this.state.readyToAnimateContents}
                                 oldXP={this.props.oldXP}
                                 newXP={this.props.newXP} />
                <div className={'more-stuff'}></div>
            </div>
        );
    }
}

EndDialog.propTypes = {
    showing: PropTypes.bool,
    levelScore: PropTypes.number,       // first line of score section, not total score
    goalBonus: PropTypes.number,        // second line of score section
    coinsThisLevel: PropTypes.number,   // for calculating third line of score section
    coinMultiplier: PropTypes.number,   // for calculating third line of score section
    oldXP: PropTypes.number,            // for rendering xp progress meter.  Will update if user watches an ad
    newXP: PropTypes.number            // for rendering xp progress meter.  Will update if user watches an ad
};

export default EndDialog;
