import * as PIXI from "pixi.js";
import ChooserLetter from "./chooserLetter";
import AnimatedContainer from "./animatedContainer";
import { PPM } from "../const/const";

export default class LetterChooser extends AnimatedContainer {
    constructor() {
        super();

        this.connectorsRoot = new PIXI.Container();
        this.addChild(this.connectorsRoot);

        this.lettersRoot = new PIXI.Container();
        this.addChild(this.lettersRoot);

        this.bonusesRoot = new PIXI.Container();
        this.addChild(this.bonusesRoot);

        this.allLettersSign = new PIXI.Sprite();
        this.addChild(this.allLettersSign);

        this.letterStars = [];
        for (let i = 0; i < 7; i++){
            const thisLetter = new ChooserLetter();
            thisLetter.visible = false;
            this.lettersRoot.addChild(thisLetter);
            this.letterStars.push(thisLetter);
        }

        this.selectedLetters = [];
        this.connectors = [];
        this.selectedBonuses = [];
        this.currentWord = "";
        this.tutorialMode = false;

        this.bonusTypeProvider = null;
        this.bonuses = [];

        this.bonusPoints = 0;
        this.bonusMultipler = 1;
        this.bonusCoins = 0;
        this.bonusTurns = 0;
        this.score = 0;
        const POINTS_PER_LETTER = 10;
        const ALL_LETTERS_BONUS_POINTS = 15;
    }

    onEnterFrame(deltaSeconds) {
        for (const letter of this.letterStars){
            letter.onEnterFrame(deltaSeconds);
        }
    }

    loadJumble(jumble, bonusTypeProvider, tutorialMode = false) {
        console.log('loadJumble');
        this.tutorialMode = tutorialMode;
        this.currentJumble = jumble;
        this.bonusTypeProvider = bonusTypeProvider;

        const letters = jumble.letters;
        this.currentWord = "";

        for (const l of this.letterStars){
            l.unselect();
            l.visible = false;
        }

        this.bonusesRoot.removeChildren();
        this.bonuses = [];

        for (const c of this.connectors){
            c.visible = false;
        }

        this.allLettersSign.visible = false;

        const theta = Math.PI * 2.0 / letters.length;
        const letterRadius = 1.7 * PPM;
        console.log('letterRadius', letterRadius);

        for (let i = 0; i < letters.length; i++){
            const letter = letters.charAt(i);
            const thisStar = this.letterStars[i];
            thisStar.setLetter(letter);
            thisStar.visible = true;
            thisStar.setPosition(Math.cos(theta * i) * letterRadius, Math.sin(theta * i) * letterRadius);

            if (i < 3){
                thisStar.select();
            }
        }
        for (let i = letters.lenth; i < this.letterStars.length; i++){
            this.letterStars[i].visible = false;
        }
    }
}