import React from "react";
import {GAME_SCENE_GAMEPLAY} from "../const/const";
import * as PIXI from "pixi.js"

class MainMenu extends React.Component {
    constructor(props) {
        super(props);

        // TODO: totally use sprite sheets to reduce load size/time
        PIXI.Loader.shared
            .add("letterConnector", './images/gameplay/letterConnector.png')
            .add("heroRestingCloud", './images/gameplay/heroRestingCloud.png')
            .add("lettersSpriteSheet", './images/gameplay/lettersSpriteSheet.json')
            .add("muncher", './images/gameplay/muncher.png')
            .add("smokeStar", './images/gameplay/smokeStar.png')
            .add("rocketSmoke", './images/gameplay/rocketSmoke.png')
            .add("trail", './images/gameplay/trail.png')
            .add("title", './images/ui/TitleScreen.jpg')
            .add("particle", './images/gameplay/particle.png')
            .load(() => {
                console.log('all loaded!');
            });
    }

    start(){
        window.GameCore._GameScene = GAME_SCENE_GAMEPLAY;
    }

    onStartClicked = () => {
        console.log('this?', this);
        this.start();
    }

    render() {
        return (
            <div className={'main-menu'}>
                <button className={'start-button'} onClick={this.onStartClicked}>Start</button>
            </div>
        );
    }
}
export default MainMenu;
