import React from "react";
import ReactDOM from "react-dom";
import App from "./js/react/App";
import "./index.scss";
import GameCore from "./js/GameCore"

const gameCore = new GameCore();
const theApp = <App gameCore={gameCore} />;
console.log('App stuff?', theApp, theApp.TestEndDialog, theApp.SetStateVal);
ReactDOM.render(theApp, document.getElementById("root"));
